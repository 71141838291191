// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/AnalyticsContext.tsx"
);
import.meta.hot.lastModified = "1736899405833.7231";
}
// REMIX HMR END

import { createContext } from 'react'

export type TrackEvent = (name: string, props?: Partial<Record<'revenue' | 'props', Record<string, unknown>>>) => void

export type PushGTMVars = (variables: Record<string, unknown>) => void

export const AnalyticsContext = createContext<{
  trackEvent: TrackEvent
  pushGTMVars: PushGTMVars
}>({
  trackEvent: () => null,
  pushGTMVars: () => null
})
